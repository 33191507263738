import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import logolight from "./../../../../../../src/assets/header/logo2.png";
import QuizTimer from "./QuizTimer";

const QuizHeader = ({ state, examName, dispatch, appTimer, onFinishQuiz ,quizData }) => {
  // State to track time left in seconds
  const [timeLeft, setTimeLeft] = useState(appTimer * 60); // Convert minutes to seconds

  useEffect(() => {
    // Only start timer if timeLeft is greater than 0
    if (timeLeft > 0) {
      const timerInterval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      // Clear interval on component unmount
      return () => clearInterval(timerInterval);
    }
  }, [timeLeft]);

  // Format the time as mm:ss
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div>
      <header className="w-full px-1 h-[100px] fixed inset-0 z-50 flex items-center justify-center bg-secondary text-light shadow-md shadow-secondary/40">
        <nav
          className={`nav-container  w-full  flex h-fit items-center gap-[20px] ${
            state.response ? "flex-row justify-between" : "flex-col"
          }`}
        >
          <div
            className={`header-underneath mx-auto flex w-full items-center justify-center gap-[20px] py-10 ${
              state.response ? "pointer-events-none hidden" : ""
            }`}
          >
            <div className="h-32">
              <img className="h-32" src={logolight} alt="logo" />
            </div>
            {state?.quizInfo?.duration && (
          <QuizTimer
            duration={state?.quizInfo?.duration}
            state={state}
            onFinishQuiz={onFinishQuiz}
           
          />
        )}
            <div></div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default QuizHeader;
