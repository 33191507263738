import { useEffect, useRef, useState } from "react";
import useFade from "../../../MainComponents/Hooks/useFade";
import SingleCourse from "../../../MainComponents/SingleCourse";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "../../../MainComponents/GlobalContext.jsx";
import SingleChapter from "../../../MainComponents/SingleChapter";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleDown,
  faChevronCircleUp,
} from "@fortawesome/free-solid-svg-icons";

function StudentHomework() {
  const [filterdata, setFilterData] = useState([]);
  const [selectedFilterType, setSelectedFilterType] = useState("reading");
  //!---- get all lectures  -------
  const [allHomeWorksData, allHomeWorksErrors, allHomeWorksLoading] = useAxios(
    process.env.REACT_APP_HOMEWORK_TAB_API,
    "GET",
    "GET"
  );

  const allAdvanced = allHomeWorksData?.data?.every(
    (lecture) => lecture.is_advanced === 1
  );
  const allBeginner = allHomeWorksData?.data.every(
    (lecture) => lecture.is_beginner === 1
  );

  // Determine if levels are mixed
  const levelsAreMixed = !(allAdvanced || allBeginner);

  // Initialize selectedLevel based on the common level
  const [selectedLevel, setSelectedLevel] = useState("all");

  useEffect(() => {
    const filteredHomeWork = allHomeWorksData?.data?.filter((lecture) => {
      const matchesLevel =
        (selectedLevel === "all" && true) ||
        (selectedLevel === "beginner" && lecture.is_beginner) ||
        (selectedLevel === "advanced" && lecture.is_advanced);
      const matchesType =
        (selectedFilterType === "writing" && lecture.is_reading === 0) ||
        (selectedFilterType === "reading" && lecture.is_reading);

      return matchesLevel && matchesType;
    });
    setFilterData(filteredHomeWork);
  }, [allHomeWorksData, selectedLevel, selectedFilterType]);

  const TitleWrapper = useRef();
  const [fade] = useFade(TitleWrapper, 100);
  if (allHomeWorksLoading) {
    return <Loader />;
  }

  if (allHomeWorksErrors) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{allHomeWorksErrors.message || "An error occurred"}</p>
      </div>
    );
  }

  return (
    <section className=" width  mb-32 h-full  dark:bg-dark dark:text-light">
      <HelmetTags title="Homework | Ms Amira Shaltout"></HelmetTags>
      <div ref={TitleWrapper} className="title-wrapper  mt-36">
        <h2
          className={`mb-12 text-center text-5xl font-bold transition-all duration-300 ease-linear ${
            fade ? "translate-y-14 opacity-0" : "translate-y-0 opacity-100"
          } `}
        >
          Homework
        </h2>
      </div>
      <div className="flex w-full flex-wrap py-10 gap-10 justify-center sm:flex-col-reverse sm:items-center mb-10">
        {/* Map through levels but conditionally include beginner and advanced */}
        {levelsAreMixed &&
          ["all", "beginner", "advanced"]?.map((level) => (
            <button
              key={level}
              onClick={() => {
                setSelectedLevel(level);
                setSelectedFilterType("reading");
              }}
              className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                selectedLevel === level && "bg-secondary text-light"
              }`}
            >
              {level.charAt(0).toUpperCase() + level.slice(1)}
            </button>
          ))}
      </div>

      <div className="flex w-full flex-wrap py-10 gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
        {["reading", "writing"].map((level) => (
          <button
            key={level}
            onClick={() => setSelectedFilterType(level)}
            className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
              selectedFilterType === level && "bg-secondary text-light"
            }`}
          >
            {level.charAt(0).toUpperCase() + level.slice(1)}
          </button>
        ))}
      </div>

      {/* Button to reverse the order */}

      <div className="lectures grid-auto-fit">
        {filterdata?.length === 0 ? (
          <p>No HomeWork</p>
        ) : (
          filterdata?.length > 0 &&
          filterdata?.map((Card) => (
            <SingleCourse
              lecture={Card}
              key={Card.key}
              type={"homeworkstudent"}
            />
          ))
        )}
      </div>
    </section>
  );
}

export default StudentHomework;
