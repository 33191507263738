import { useEffect, useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { DatePicker } from "antd";
import { Line } from "react-chartjs-2";

const { RangePicker } = DatePicker;
const ViewStudent = () => {
  const { studentID } = useParams();
  const [date, setDate] = useState([]);

  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    depdency: false,
    data: {},
  });
  const [successData, errorData, loadingSuccess] = useAxios(
    process.env.REACT_APP_GET_STUDENTS_RESULTS_API,
    "POST",
    submitAdd.flag,
    submitAdd.depdency,
    submitAdd.data,
    true
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Student Details",
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        min: 20,
        max: 100,
        ticks: {
          stepSize: 20,
        },
      },
    },
  };

  /* GRAPHS */
  const chartStudentData = {
    labels: successData?.data?.map((item) => item.percentage) || [],
    datasets: [
      {
        label: "Percentage",
        data: successData?.data?.map((item) => item.percentage) || [],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        tension: 0.4,
      },
    ],
  };

  // Handle the date change
  const handleChangeDate = (dates) => {
    if (dates[0] && dates[1]) {
      const startDate = dates[0];
      const endDate = dates[1];
      setDate([startDate, endDate]);
    }
  };

  useEffect(() => {
    if (date.length === 2) {
      const [from, to] = date;
      setSubmitAdd({
        flag: "Change Table",
        depdency: !submitAdd.depdency,
        data: {
          id: studentID,
          from,
          to,
        },
      });
    }
  }, [date, studentID]);

  const table = useTable(
    "purchaseHistory",
    "",
    "",
    "",
    "",
    "",
    true,
    successData?.data,
    "",
    "",
    loadingSuccess
  );

  return (
    <section className="h-full w-full  px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Students | Ms Amira Shaltout"></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">
          View student
        </h2>
      </div>
      <div className="w-1/2 sm:w-full py-10">
        <RangePicker
          className="signin-inputs"
          onChange={(_, dateString) => handleChangeDate(dateString)}
        />
      </div>
      <div className="h-fit  w-full">{table}</div>
      <div className="space-y-5 py-10">
        <h2 className="w-full text-4xl font-bold md:text-center">
          Student Details
        </h2>
        <Line data={chartStudentData} options={options} />
      </div>
    </section>
  );
};

export default ViewStudent;
