import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";
import SingleChapter from "../../../../MainComponents/SingleChapter";
import { useState, useEffect } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";

export default function AdminHomework() {
  const [reFetch, setReFtech] = useState(false);
  const [academicSection, setAcademicSection] = useState("all");
  const [filterdata, setFilterData] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("beginner");
  const [selectedFilterType, setSelectedFilterType] = useState("reading");
  const [getHomeWork, errorHomeWork, LoadingHomework] = useAxios(
    process.env.REACT_APP_GET_HOMEWORK_API,
    "GET",
    "GET",
    reFetch
  );

  useEffect(() => {
    // Filter homework based on section and level
    const filteredHomeWork = getHomeWork?.data?.filter((lecture) => {
      if (academicSection === "all") return true;

      const matchesSection =
        academicSection === "visibletoall"
          ? lecture.section === null
          : lecture.section === academicSection;
      const matchesLevel =
        selectedLevel === null ||
        (selectedLevel === "beginner" && lecture.is_beginner) ||
        (selectedLevel === "advanced" && lecture.is_advanced);
      const matchesType =
        (selectedFilterType === "writing" && lecture.is_reading === 0) ||
        (selectedFilterType === "reading" && lecture.is_reading);
      return matchesSection && matchesLevel && matchesType;
    });
    setFilterData(filteredHomeWork);
  }, [getHomeWork, selectedLevel, academicSection, selectedFilterType]);

  if (LoadingHomework) return <Loader />;

  return (
    <section className="px-10 py-10">
      <HelmetTags title="HomeWork | Ms. Amira Shaltout" />

      <div className="flex w-full gap-10 md:justify-evenly">
        <Link
          to={"/admin/homework/add-homework"}
          className="mr-4 rounded-xl border-2 border-secondary order-1 bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90"
        >
          <FontAwesomeIcon className="mr-4" icon={faPlus} />
          Add Homework
        </Link>
      </div>

      {/* Section Buttons */}
      <div className="flex w-full flex-wrap py-10 gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
        {["all", "est", "act", "dsat", "visibletoall"].map((section) => (
          <button
            key={section}
            onClick={() => {
              setAcademicSection(section);
              setSelectedLevel("beginner");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
              academicSection === section && "bg-secondary text-light"
            }`}
          >
            {section === "visibletoall"
              ? "Visible To All"
              : section.toUpperCase()}
          </button>
        ))}
      </div>

      {/* Level Buttons */}
      {academicSection !== "all" && (
        <div className="flex w-full flex-wrap py-10 gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
          {["beginner", "advanced"].map((level) => (
            <button
              key={level}
              onClick={() => {
                setSelectedLevel(level);
                setSelectedFilterType("reading");
              }}
              className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                selectedLevel === level && "bg-secondary text-light"
              }`}
            >
              {level.charAt(0).toUpperCase() + level.slice(1)}
            </button>
          ))}
        </div>
      )}
      {academicSection !== "all" &&
        (selectedLevel === "beginner" || selectedLevel === "advanced") && (
          <div className="flex w-full flex-wrap py-10 gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
            {["reading", "writing"].map((level) => (
              <button
                key={level}
                onClick={() => setSelectedFilterType(level)}
                className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                  selectedFilterType === level && "bg-secondary text-light"
                }`}
              >
                {level.charAt(0).toUpperCase() + level.slice(1)}
              </button>
            ))}
          </div>
        )}

      {/* Homework List */}
      <div className="py-10 lectures grid-auto-fit">
        {filterdata?.length > 0 ? (
          filterdata.map((Card) => (
            <SingleChapter
              type={"homework"}
              admin
              setReFetch={setReFtech}
              reFetch={reFetch}
              chapter={Card}
              key={Card.key}
            />
          ))
        ) : (
          <p>No Homework Found</p>
        )}
      </div>
    </section>
  );
}
