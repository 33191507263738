import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useReducer, useState } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../MainComponents/HelmetTags.jsx";
import useAxios from "../../../MainComponents/Hooks/useAxios.jsx";
import "../../../SASS/Register.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Register({ edit, studentInfo, type }) {
  const [countrySelected, setCountrySelected] = useState("20");
  const [countryParentSelected, setCountryParentSelected] = useState("20");
  const [mobile, setMobile] = useState("");
  const [parentMobile, setParentMobile] = useState("");

  const [values, setValues] = useState(null);
  const { studentID } = useParams();
  const navigate = useNavigate();
  const [hide, setHide] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    values,
  });
  const watchPlace = watch("is_online");
  const watchGrade = watch("grade");

  function reducer(state, action) {
    switch (action.type) {
      case "setStates": {
        return {
          ...state,
          states: action.payload,
        };
      }
      case "setGroups": {
        return {
          ...state,
          groups: action.payload,
        };
      }
      case "setSelectedState": {
        return {
          ...state,
          selectedState: action.payload,
        };
      }
      case "setGovernorate": {
        return {
          ...state,
          governorate: action.payload,
        };
      }

      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setHide": {
        return {
          ...state,
          hide: !state.hide,
        };
      }
      case "setSubmitLoading": {
        return {
          ...state,
          submitLoading: action.payload,
        };
      }

      case "setSubmitEditStudent": {
        return {
          ...state,
          submitEditStudent: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    hide: false,
    states: [],
    governorate: [],
    cities: [],
    groups: [],
    selectedState: "",
    errors: {},
    submitLoading: false,
    noCode: false,
    submitEditStudent: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const watchCountries = watch("countries");
  const watchGovernorate = watch("governorate");

  useEffect(() => {
    const stateSelector = document.querySelector(".state-selector");
    const citySelector = document.querySelector(".city-selector");
    stateSelector.addEventListener("change", function handleStateSelected(e) {
      dispatch({
        type: "setSelectedState",
        payload: e.target.selectedIndex,
      });
    });
    citySelector?.addEventListener("change", function handleStateSelected(e) {
      dispatch({
        type: "setCitySelected",
        payload: e.target.selectedIndex,
      });
    });
    async function fetchData() {
      try {
        const res = await axios(process.env.REACT_APP_GOVERNORATES_API);
        const data = await res.data;
        dispatch({
          type: "setStates",
          payload: data?.states,
        });
        dispatch({
          type: "setGroups",
          payload: data?.groups,
        });
      } catch (error) {
        toast.error(
          "An error occurred while loading cities. Please try again."
        );
      }
    }
    fetchData();
  }, []);

  // const [GetAllCourseSuccsess, GetAllCourseErrors, GetAllCourseLoading] =
  //   useAxios(process.env.REACT_APP_ADMIN_STUDENT_COURSE_API, "GET", "GET", "");

  const [StudentEditSuccess, StudentEditErrors, StudentEditLoading] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_INFO_API,
    "POST",
    state.submitEditStudent.flag,
    state.submitEditStudent.dependency,
    state.submitEditStudent.data,
    true
  );

  useEffect(() => {
    if (StudentEditSuccess) {
      reset();
      navigate(-1);
    }
  }, [StudentEditSuccess]);

  useEffect(() => {
    if (studentInfo) {
      dispatch({
        type: "setSelectedState",
        payload: studentInfo?.data[0]?.selected_state,
      });
      setValue("governorate", studentInfo?.data[0]?.governorate);
      // delete studentInfo?.data[0]?.governorate;
      // delete studentInfo?.data[0]?.is_online;
      setValues({
        ...studentInfo?.data[0],
      });
      setValue("countries", String(studentInfo?.data[0]?.countries));
      setValue("is_advanced", studentInfo?.data[0]?.is_advanced);
      setValue("is_beginner", studentInfo?.data[0]?.is_beginner);

      setMobile(studentInfo?.data[0].mobile);
      setParentMobile(studentInfo?.data[0].parent_mobile);
    }
  }, [setValue, studentInfo]);

  function postData(studentData) {
    axios
      .post(process.env.REACT_APP_REGISTER_API, studentData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        const data = res.data;
        toast.success(data.message);
        dispatch({
          type: "setSubmitLoading",
          payload: false,
        });
        !edit ? navigate("/signin") : navigate(-1);
        reset();
      })
      .catch((error) => {
        if (error.response.data?.errors) {
          dispatch({
            type: "setErrors",
            payload: error.response.data?.errors,
          });
          dispatch({
            type: "setSubmitLoading",
            payload: false,
          });

          const errorMessages = Object.keys(error.response.data.errors).map(
            (field) => (
              <div key={field}>
                {error.response.data.errors[field].map((errorMsg, index) => (
                  <p key={index}>{errorMsg}</p>
                ))}
              </div>
            )
          );

          toast.error(<div>{errorMessages}</div>);
        } else {
          toast.error(<div>{error.response.data.message}</div>);
        }
      });
  }

  useEffect(() => {
    if (watchCountries) {
      dispatch({
        type: "setGovernorate",
        payload: state?.states?.filter(
          (state) => state.id === +watchCountries
        )[0]?.governorates,
      });
    }

    if (watchCountries === "") {
      dispatch({
        type: "setGovernorate",
        payload: state?.states?.filter(
          (state) => state.id === 63 //  egypt
        )[0]?.governorates,
      });

      setValue("countries", "63");
    }
  }, [studentInfo?.data, state?.states, watchCountries]);

  const onSubmit = (data) => {
    if (mobile.trim().includes(" ")) {
      toast.error("Mobile number should not contain any spaces.");
      return;
    } else if (parentMobile.trim().includes(" ")) {
      toast.error("Parent mobile number should not contain any spaces.");
      return;
    }
    const studentData = edit
      ? {
          ...data,
          id: studentID,
          mobile: mobile,
          parent_mobile: parentMobile,
          mobile_key: countrySelected,
          mobileparent_key: countryParentSelected,
          profile_pic: data?.profile_pic[0] || null,
          governorate: data?.governorate ? data?.governorate : "1",
          group: +data?.is_online === 1 ? null : data.group,
          magnetic_id: data?.magnetic_id || null,
          active: data?.active === true ? 1 : 0,
          is_advanced: data?.is_advanced === true ? 1 : 0,
          is_beginner: data?.is_beginner === true ? 1 : 0,
        }
      : {
          ...data,
          mobile: mobile,
          parent_mobile: parentMobile,
          mobile_key: countrySelected,
          mobileparent_key: countryParentSelected,
          profile_pic: data?.profile_pic[0] || null,
          governorate: data?.governorate ? data?.governorate : "1",
          group: +data?.is_online === 1 ? null : data.group,
          is_advanced: data?.is_advanced === true ? 1 : 0,
          is_beginner: data?.is_beginner === true ? 1 : 0,
        };
    if (edit) {
      dispatch({
        type: "setSubmitEditStudent",
        payload: {
          flag: "AddEditStudent",
          dependency: !state.submitEditStudent.dependency,
          data: studentData,
        },
      });
    } else {
      dispatch({
        type: "setSubmitLoading",
        payload: true,
      });

      postData(studentData);
    }
  };
  if (type === "register") {
    return (
      <section className="container-register ">
        <HelmetTags
          title={`${
            edit ? "Edit" : "Add New"
          } Student | Ms Amira Shaltout Amira Shaltout`}
          description={`${
            edit ? "Edit" : "Add New"
          } Student | Ms Amira Shaltout Amira Shaltout`}
          index
        ></HelmetTags>
        {!edit && (
          <div className="flex flex-col items-center justify-center gap-4">
            <img
              className="h-60 w-auto cursor-pointer "
              src="https://api.amirashaltout.com/uploads/assets/logo-dash-light.png"
              alt="logo"
              onClick={() => navigate("/")}
            />
            <h1 className="text-4xl font-bold text-black">
              Create New Account
            </h1>
            <p className="text-2xl opacity-80 text-black">
              Welcome back! Please enter your information.
            </p>
          </div>
        )}

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="pt-10 form_area-register"
        >
          {/** Username and Email fields */}

          <p class="title-register">SIGN UP</p>
          {/** Name filed */}
          <div className="form_group-register">
            <label htmlFor="name" className="sub_title-register">
              Student Name
            </label>
            <input
              className="form_style-register"
              type="text"
              id="name"
              placeholder="Student Name"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,
                pattern: /^[A-Za-z\s]+$/,
                maxLength: 25,
                minLength: 3,
              })}
            />
            {errors.name && (
              <p className="pt-4 text-md text-red-500">
                {errors.name.type === "required" && "This field is required."}
                {errors.name.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
                {errors.name.type === "maxLength" &&
                  "Available name max length is 25."}
                {errors.name.type === "minLength" &&
                  " Please enter at least 3 letters."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.name && (
                <p className="pt-4 text-md text-red-500">
                  {state.errors.name[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.name && (
                  <p className="pt-4 text-md text-red-500">
                    {StudentEditErrors?.response?.data.errors.name[0]}
                  </p>
                )
            }
          </div>

          {/*    Username and Email fields 
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-6">
             Username Field 
            <div
              className={`flex  w-full  flex-col items-start justify-center  gap-2 md:w-full`}
            >
              <label htmlFor="username">Username</label>
  
              <input
                className="signin-inputs  w-full "
                type="text"
                id="username"
                placeholder="username"
                name="username"
                autoComplete="on"
                {...register("username", {
                  required: true,
                  pattern: /^[A-Za-z0-9_.]+$/,
                })}
              />
              {errors.username && (
                <p className="pt-4 text-md text-red-500">
                  {errors.username.type === "required" &&
                    "This field is required."}
                  {errors.username.type === "pattern" &&
                    " Invalid Username Address."}
                </p>
              )}
              {
                //!-------server errors -----
                state.errors && state.errors.username && (
                  <p className="pt-4 text-md text-red-500">
                    {state.errors.username[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----
  
                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.username && (
                    <p className="pt-4 text-md text-red-500">
                      {StudentEditErrors?.response?.data.errors.username[0]}
                    </p>
                  )
              }
            </div>
          </div> */}

          {/** Password and Father's Job fileds */}
          {!edit && (
            <>
              {/** Password  */}

              <div className="form_group-register ">
                <label htmlFor="password" className="sub_title-register">
                  Password
                </label>
                <input
                  id="password"
                  className="form_style-register"
                  type={`${hide ? "text" : "password"}`}
                  placeholder="Password"
                  name="password"
                  autoComplete="on"
                  {...register("password", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                  })}
                />
                {hide ? (
                  <FontAwesomeIcon
                    onClick={() => setHide(!hide)}
                    className="absolute right-3 bottom-6 h-6 w-6 cursor-pointer"
                    icon={faEye}
                  />
                ) : (
                  <FontAwesomeIcon
                    onClick={() => setHide(!hide)}
                    className="absolute right-3 bottom-6 h-6 w-6 cursor-pointer"
                    icon={faEyeSlash}
                  />
                )}
              </div>
              {errors.password && (
                <p className="pt-4 text-md text-red-500">
                  {errors.password.type === "required" &&
                    "This field is required."}
                  {errors.password.type === "maxLength" &&
                    "password Max length is 25 char."}
                  {errors.password.type === "minLength" &&
                    "Password Min length is 6 char."}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.password && (
                  <p className="pt-4 text-md text-red-500">
                    {state.errors.password[0]}
                  </p>
                )
              }

              {/** Confirm Password  */}

              <div className="form_group-register ">
                <label
                  htmlFor="password_confirmation"
                  className="sub_title-register"
                >
                  Confirm Password
                </label>
                <input
                  id="password_confirmation"
                  className="form_style-register"
                  type={`${hide ? "text" : "password"}`}
                  placeholder="Confirm Password"
                  name="password_confirmation"
                  autoComplete="on"
                  {...register("password_confirmation", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                    validate: (value) => value === getValues("password"),
                  })}
                />
                {/*  {hide ? (
                    <FontAwesomeIcon
                      onClick={() => setHide(!hide)}
                      className="absolute right-3 bottom-6 h-6 w-6 cursor-pointer"
                      icon={faEye}
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={() => setHide(!hide)}
                      className="absolute right-3 bottom-6 h-6 w-6 cursor-pointer"
                      icon={faEyeSlash}
                    />
                  )} */}
              </div>
              {errors.password_confirmation && (
                <p className="pt-4 text-md text-red-500">
                  {errors.password_confirmation.type === "required" &&
                    "This field is required."}
                  {errors.password_confirmation.type === "maxLength" &&
                    "password_confirmation Max length is 25 char."}
                  {errors.password_confirmation.type === "minLength" &&
                    "Password Min length is 6 char."}
                  {errors.password_confirmation.type === "validate" &&
                    "Passwords don't match."}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.password_confirmation && (
                  <p className="pt-4 text-md text-red-500">
                    {state.errors.password_confirmation[0]}
                  </p>
                )
              }
            </>
          )}

          {/** student and parents mobile numbers fields */}

          {/** Student Mobile field */}

          <div className="form_group-register">
            <label htmlFor="mobile" className="sub_title-register">
              Student Mobile
            </label>

            {/* <ReactFlagsSelect
                    selected={countrySelected}
                    onSelect={(code) => setCountrySelected(code)}
                    className="p-0"
                  /> */}

            <PhoneInput
              country={"253"}
              value={countrySelected}
              onChange={(phone) => setCountrySelected(phone)}
              className=" w-[290px!important] "
              inputProps={{
                name: "mobile",
                value: mobile,
                onChange: (e) => setMobile(e.target.value),
                placeholder: "1234567891",
                className: " form_style-register pl-[50px!important] ",
                required: true,
              }}
            />
            {/* <input
                      className=" outline-0"
                      type="number"
                      inputMode="numeric"
                      id="mobile"
                      placeholder="Student Mobile"
                      name="mobile"
                      min="1"
                      autoComplete="on"
                      {...register("mobile", {
                        required: true,
                        pattern: /^[\d]{11}/,
                        // maxLength: 11,
                        min: 1,
                      })}
                    /> */}
          </div>
          {errors.mobile && (
            <p className="pt-4 text-md text-red-500">
              {errors.mobile.type === "required" && "This field is required."}
              {errors.mobile.type === "pattern" && " Invalid Mobile Number."}
              {errors.mobile.type === "maxLength" && " Invalid Mobile Number."}
              {errors.mobile.type === "min" && " Invalid Mobile Number."}
              {errors.mobile.type === "validate" &&
                "Please, Don't Enter the Same Mobile Number."}
            </p>
          )}
          {
            //!-------server errors -----

            state.errors && state.errors.mobile && (
              <p className="pt-4 text-md text-red-500">
                {state.errors.mobile[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.mobile && (
                <p className="pt-4 text-md text-red-500">
                  {StudentEditErrors?.response?.data.errors.mobile[0]}
                </p>
              )
          }

          {/** Father's Mobile field */}

          <div className=" form_group-register">
            <label htmlFor="parent_mobile" className="sub_title-register">
              Parents Number
            </label>

            <PhoneInput
              country={"eg"}
              value={countryParentSelected}
              onChange={(phone) => setCountryParentSelected(phone)}
              className="w-[290px!important] "
              inputProps={{
                name: "parent_mobile",
                value: parentMobile,
                onChange: (e) => setParentMobile(e.target.value),
                placeholder: "1234567891",
                className: " form_style-register pl-[50px!important] ",
                required: true,
              }}
            />
          </div>
          {errors.parent_mobile && (
            <p className="pt-4 text-md text-red-500">
              {errors.parent_mobile.type === "required" &&
                "This field is required."}
              {errors.parent_mobile.type === "pattern" &&
                " Invalid Mobile Number."}
              {errors.parent_mobile.type === "maxLength" &&
                " Invalid Mobile Number."}
              {errors.parent_mobile.type === "min" && " Invalid Mobile Number."}
              {errors.parent_mobile.type === "validate" &&
                "Please, Don't Enter the Same Mobile Number."}
            </p>
          )}
          {
            //!-------server errors -----

            state.errors && state.errors.parent_mobile && (
              <p className="pt-4 text-md text-red-500">
                {state.errors.parent_mobile[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.parent_mobile && (
                <p className="pt-4 text-md text-red-500">
                  {StudentEditErrors?.response?.data.errors.parent_mobile[0]}
                </p>
              )
          }

          {/** Stage  filed */}
          {/* Gender  */}
          <div className="form_group-register">
            <label htmlFor="gender" className="sub_title-register">
              Gender
            </label>
            <select
              name="gender"
              id="gender"
              className="form_style-register "
              {...register("gender", { required: true })}
            >
              <option value="0">Male</option>
              <option value="1">Famale</option>
            </select>
            {errors.gender && (
              <p className="pt-4 text-md text-red-500">
                {errors.gender.type === "required" && "This field is required."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.gender && (
                <p className="pt-4 text-md text-red-500">
                  {state.errors.gender[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.gender && (
                  <p className="pt-4 text-md text-red-500">
                    {StudentEditErrors?.response?.data.errors.gender[0]}
                  </p>
                )
            }
          </div>
          <div className="form_group-register">
            <label htmlFor="grade" className="sub_title-register">
              Grade
            </label>
            <select
              className="form_style-register"
              name="grade"
              id="grade"
              {...register("grade", { required: true })}
            >
              {/*       <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option> */}
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>

            {errors.class && (
              <p className="text-xs text-red-500 ">
                {errors.class.class === "required" && "This field is required."}
              </p>
            )}
            {
              //!-------server errors -----

              StudentEditErrors &&
                StudentEditErrors?.response?.data?.errors?.class && (
                  <p className="w-full text-end text-xs text-red-500  ">
                    {StudentEditErrors?.response?.data?.errors?.class[0]}
                  </p>
                )
            }
          </div>

          <div className="form_group-register">
            <label htmlFor="section" className="sub_title-register">
              Courses
            </label>
            <select
              name="section"
              id="section"
              placeholder="Select Courses"
              className="form_style-register "
              {...register("section", { required: true })}
            >
              <option value="est">EST</option>
              <option value="act">ACT</option>
              <option value="dsat">DIGITAL SAT</option>
            </select>

            {errors.section && (
              <p className="text-xs text-red-500 ">
                {errors.section === "required" && "This field is required."}
              </p>
            )}
            {
              //!-------server errors -----

              StudentEditErrors &&
                StudentEditErrors?.response?.data?.errors?.section && (
                  <p className="w-full text-end text-xs text-red-500  ">
                    {StudentEditErrors?.response?.data?.errors?.section[0]}
                  </p>
                )
            }
          </div>
          <div className="form_group-register flex gap-2 flex-wrap">
            <div className="form_group-register flex gap-2 flex-wrap">
              <div className="flex w-full items-center justify-end gap-10 md:justify-center">
                <label className="visibility-switch order-2 text-start">
                  <input
                    className=""
                    id="active"
                    name="active"
                    {...register("is_advanced", {
                      required: false,
                    })}
                    type="checkbox"
                  />
                  <span className="visibility-slider">
                    <svg
                      className="slider-icon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  </span>
                </label>
                <div>
                  <label
                    className="w-full cursor-pointer truncate order-1 text-start text-black"
                    htmlFor="active"
                  >
                    Advanced
                  </label>
                </div>
              </div>

              <div className="flex w-full items-center justify-end gap-10 md:justify-center">
                <label className="visibility-switch order-2 text-start">
                  <input
                    className=""
                    id="active"
                    name="active"
                    {...register("is_beginner", {
                      required: false,
                    })}
                    type="checkbox"
                  />
                  <span className="visibility-slider">
                    <svg
                      className="slider-icon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  </span>
                </label>
                <div>
                  <label
                    className="w-full cursor-pointer truncate order-1 text-start text-black"
                    htmlFor="active"
                  >
                    Beginner
                  </label>
                </div>
              </div>

              {errors.sectionType && (
                <p className="text-xs text-red-500 ">
                  {errors.sectionType === "required" &&
                    "This field is required."}
                </p>
              )}
              {
                //!-------server errors -----

                StudentEditErrors &&
                  StudentEditErrors?.response?.data?.errors?.sectionType && (
                    <p className="w-full text-end text-xs text-red-500  ">
                      {
                        StudentEditErrors?.response?.data?.errors
                          ?.sectionType[0]
                      }
                    </p>
                  )
              }
            </div>

            {errors.sectionType && (
              <p className="text-xs text-red-500 ">
                {errors.sectionType === "required" && "This field is required."}
              </p>
            )}
            {
              //!-------server errors -----

              StudentEditErrors &&
                StudentEditErrors?.response?.data?.errors?.sectionType && (
                  <p className="w-full text-end text-xs text-red-500  ">
                    {StudentEditErrors?.response?.data?.errors?.sectionType[0]}
                  </p>
                )
            }
          </div>

          <div className="form_group-register ">
            <label className="sub_title-register " htmlFor="is_online">
              Attendance
            </label>
            <select
              className=" form_style-register"
              name="is_online"
              id="is_online"
              {...register("is_online", { required: true })}
            >
              <option value="1">Online</option>
              <option value="0">Center</option>
            </select>

            {errors.is_online && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {errors.is_online.type === "required" &&
                  "Please fill out this field"}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.is_online && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {state.errors.is_online[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.is_online && (
                  <p className="w-full text-start text-[14px] text-blue-900  ">
                    {StudentEditErrors?.response?.data.errors.is_online[0]}
                  </p>
                )
            }
          </div>

          {/** center or group */}
          {+watchPlace === 0 && (
            <div className=" form_group-register">
              <label className="sub_title-register" htmlFor="group">
                Group
              </label>
              <select
                className={` form_style-register `}
                name="group"
                id="group"
                {...register("group", { required: true })}
              >
                <option value="" disabled selected>
                  Select Group
                </option>

                {watchPlace === "0" || watchPlace === 0 ? (
                  <>
                    {state.groups
                      /* .filter((group) => +group.stage === +watchGrade) */
                      .map((group) => (
                        <>
                          <option value={group.id}>{group.name}</option>
                        </>
                      ))}
                  </>
                ) : null}
              </select>

              {errors.group && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {errors.group.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.group && (
                  <p className="w-full text-start text-[14px] text-blue-900  ">
                    {state.errors.group[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.group && (
                    <p className="w-full text-start text-[14px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.group[0]}
                    </p>
                  )
              }
            </div>
          )}
          {edit && (
            <div className="form_group-register flex gap-2 flex-wrap">
              <div className=" flex  items-center justify-center gap-2 ">
                <input
                  className="cursor-pointer"
                  type="radio"
                  id="scientific"
                  value="advanced"
                  {...register("sectionType", { required: true })}
                />
                <label
                  className="cursor-pointer text-start text-black"
                  htmlFor="sectionType"
                >
                  Advanced
                </label>
              </div>
              <div className=" flex  items-center justify-center gap-2 ">
                <input
                  className="cursor-pointer"
                  type="radio"
                  id="scientific"
                  value="beginner"
                  {...register("sectionType", { required: true })}
                />
                <label
                  className="cursor-pointer text-start text-black"
                  htmlFor="sectionType"
                >
                  Beginner
                </label>
              </div>

              {errors.sectionType && (
                <p className="text-xs text-red-500 ">
                  {errors.sectionType === "required" &&
                    "This field is required."}
                </p>
              )}
              {
                //!-------server errors -----

                StudentEditErrors &&
                  StudentEditErrors?.response?.data?.errors?.sectionType && (
                    <p className="w-full text-end text-xs text-red-500  ">
                      {
                        StudentEditErrors?.response?.data?.errors
                          ?.sectionType[0]
                      }
                    </p>
                  )
              }
            </div>
          )}
          {/** active and magnetic_id fields */}
          {edit && (
            <>
              {/** active filed */}

              <div className="form_group-register">
                <label className="sub_title-register">
                  <input
                    className="form_style-register"
                    id="active"
                    name="active"
                    {...register("active", {
                      required: false,
                    })}
                    type="checkbox"
                  />
                  <span className="visibility-slider">
                    <svg
                      className="slider-icon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  </span>
                </label>
                <div>
                  <label
                    className="w-full cursor-pointer truncate order-1 text-start"
                    htmlFor="active"
                  >
                    Activation
                  </label>
                </div>
              </div>

              {errors.active && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.active.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.active && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.active[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.active && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.active[0]}
                    </p>
                  )
              }

              {/**magnetic_id */}
              <div className=" form_group-register">
                <label className="sub_title-register" htmlFor="magnetic_id">
                  Activation card
                </label>
                <input
                  className="form_style-register"
                  type="text"
                  id="magnetic_id"
                  placeholder="Activation card"
                  name="magnetic_id"
                  autoComplete="on"
                  {...register("magnetic_id", {
                    pattern: /^[A-Za-z0-9-_]*$/,
                    minLength: 3,
                  })}
                />
                {errors.magnetic_id && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.magnetic_id.type === "required" &&
                      "Please fill out this field"}
                    {errors.magnetic_id.type === "pattern" &&
                      "Only English letters and numbers are allowed"}
                    {errors.magnetic_id.type === "maxLength" &&
                      "The maximum number of characters is 20 characters"}
                    {errors.magnetic_id.type === "minLength" &&
                      "Please write at least 3 characters"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.magnetic_id && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.magnetic_id[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.magnetic_id && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {
                          StudentEditErrors?.response?.data.errors
                            .magnetic_id[0]
                        }
                      </p>
                    )
                }
              </div>
            </>
          )}

          {/* Countries */}
          <div className="form_group-register  ">
            <label className="sub_title-register" htmlFor="countries">
              Countries
            </label>
            <select
              className="form_style-register state-selector"
              name="countries"
              id="countries"
              defaultValue={"63"}
              {...register("countries", { required: true })}
            >
              {state?.states?.map((state) => (
                <option
                  key={state.id}
                  value={state.id}
                  selected={
                    studentInfo?.data[0]?.country
                      ? studentInfo?.data[0]?.country === state.id
                      : state.id === 63
                  }
                >
                  {state.Country}
                </option>
              ))}
            </select>
            {errors.countries && (
              <p className="w-full text-start text-[12px] text-blue-900  ">
                {errors.countries.type === "required" &&
                  "Please fill out this field"}
              </p>
            )}
          </div>

          {/** Governorate and City fields */}

          {/** Governorate filed */}
          <div className="form_group-register">
            {String(watchCountries) === "63" ? (
              <>
                <label className="sub_title-register" htmlFor="governorate">
                  Governorate
                </label>
                <select
                  name="governorate"
                  id="governorate"
                  className="form_style-register"
                  defaultValue={"1"}
                  {...register("governorate", { required: false })}
                >
                  {state?.governorate?.map((city) => (
                    <option
                      key={city.id}
                      value={city.id}
                      selected={
                        studentInfo?.data[0]?.governorate
                          ? studentInfo?.data[0]?.governorate === city.id
                          : city.id === 1
                      }
                    >
                      {city.state}
                    </option>
                  ))}
                </select>
                {errors.governorate && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.governorate.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.governorate && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.governorate[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.governorate && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {
                          StudentEditErrors?.response?.data.errors
                            .governorate[0]
                        }
                      </p>
                    )
                }
              </>
            ) : null}
          </div>

          {/** School and Activation Code fields */}

          {/** School  */}
          <div className={` form_group-register`}>
            <label htmlFor="school" className="sub_title-register">
              School Name
            </label>
            <input
              className="form_style-register"
              type="text"
              id="school"
              placeholder="School"
              name="school"
              autoComplete="on"
              {...register("school", {
                required: true,
                //pattern: /^[A-Za-z]+$/,
                maxLength: 80,
                minLength: 4,
              })}
            />
            {errors.school && (
              <p className="pt-4 text-md text-red-500">
                {errors.school.type === "required" && "This field is required."}
                {errors.school.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
                {errors.school.type === "maxLength" &&
                  "Available name max length is 80."}
                {errors.school.type === "minLength" &&
                  " Please enter at least 4 letters."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.school && (
                <p className="pt-4 text-md text-red-500">
                  {state.errors.school[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.school && (
                  <p className="pt-4 text-md text-red-500">
                    {StudentEditErrors?.response?.data.errors.school[0]}
                  </p>
                )
            }
          </div>

          {/** User Image  */}
          {!edit && (
            <div className="form_group-register">
              <label className="sub_title-register" htmlFor="profile_pic">
                Upload a personal photo (Max Size 5 MB only Jpg/Jpeg/Png/Webp )
              </label>

              <input
                id="profile_pic"
                className="form_style-register mx-auto"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/webp"
                name="profile_pic"
                {...register("profile_pic", {
                  required: edit ? false : true,
                  validate: (value) => !(value[0]?.size > 5000000),
                })}
              />

              {errors.profile_pic && (
                <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                  {errors.profile_pic.type === "required" &&
                    "Please add a personal photo"}
                  {errors.profile_pic.type === "validate" &&
                    "Maximum image size is 5MB"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.profile_pic && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.profile_pic[0]}
                  </p>
                )
              }
            </div>
          )}

          {/** Submit Button */}
          <button
            disabled={!isValid || state.submitLoading || StudentEditLoading}
            className=" btn-register "
            type="submit"
          >
            {state.submitLoading || StudentEditLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : edit ? (
              "Edit Student"
            ) : (
              "Register"
            )}
          </button>
          <p className="pb-10 pt-3">
            Have an Account?
            <Link class="link-register" to={"/signin"}>
              Login Here!
            </Link>
          </p>
        </form>
      </section>
    );
  }
  return (
    <section className=" w-full flex min-h-screen flex-col items-center justify-center gap-12 py-12 md:gap-6 ">
      <HelmetTags
        title={`${
          edit ? "Edit" : "Add New"
        } Student | Ms Amira Shaltout Amira Shaltout`}
        description={`${
          edit ? "Edit" : "Add New"
        } Student | Ms Amira Shaltout Amira Shaltout`}
        index
      ></HelmetTags>
      {!edit && (
        <div className="flex flex-col items-center justify-center gap-4">
          <img
            className="h-60 w-auto cursor-pointer "
            src="https://api.amirashaltout.com/uploads/assets/logo-dash-light.png"
            alt="logo"
            onClick={() => navigate("/")}
          />
          <h1 className="text-4xl font-bold">Create New Account</h1>
          <p className="text-2xl opacity-80">
            Welcome back! Please enter your information.
          </p>
        </div>
      )}

      <form
        encType="multipart/form-data"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl   bg-white p-10 shadow-lg shadow-blue/50"
      >
        {/** Username and Email fields */}
        <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-6">
          {/** Name filed */}
          <div className=" flex w-full flex-col items-start justify-center gap-2  md:w-full">
            <label htmlFor="name">Student Name</label>
            <input
              className="signin-inputs w-full "
              type="text"
              id="name"
              placeholder="Student Name"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,
                pattern: /^[A-Za-z\s]+$/,
                maxLength: 25,
                minLength: 3,
              })}
            />
            {errors.name && (
              <p className="pt-4 text-md text-red-500">
                {errors.name.type === "required" && "This field is required."}
                {errors.name.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
                {errors.name.type === "maxLength" &&
                  "Available name max length is 25."}
                {errors.name.type === "minLength" &&
                  " Please enter at least 3 letters."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.name && (
                <p className="pt-4 text-md text-red-500">
                  {state.errors.name[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.name && (
                  <p className="pt-4 text-md text-red-500">
                    {StudentEditErrors?.response?.data.errors.name[0]}
                  </p>
                )
            }
          </div>
        </div>

        {/*    Username and Email fields 
        <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-6">
           Username Field 
          <div
            className={`flex  w-full  flex-col items-start justify-center  gap-2 md:w-full`}
          >
            <label htmlFor="username">Username</label>

            <input
              className="signin-inputs  w-full "
              type="text"
              id="username"
              placeholder="username"
              name="username"
              autoComplete="on"
              {...register("username", {
                required: true,
                pattern: /^[A-Za-z0-9_.]+$/,
              })}
            />
            {errors.username && (
              <p className="pt-4 text-md text-red-500">
                {errors.username.type === "required" &&
                  "This field is required."}
                {errors.username.type === "pattern" &&
                  " Invalid Username Address."}
              </p>
            )}
            {
              //!-------server errors -----
              state.errors && state.errors.username && (
                <p className="pt-4 text-md text-red-500">
                  {state.errors.username[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.username && (
                  <p className="pt-4 text-md text-red-500">
                    {StudentEditErrors?.response?.data.errors.username[0]}
                  </p>
                )
            }
          </div>
        </div> */}

        {/** Password and Father's Job fileds */}
        {!edit && (
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-6">
            {/** Password  */}

            <div className="w-1/2 md:w-full">
              <div className="relative flex flex-col items-start justify-center gap-2 ">
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  className="signin-inputs w-full"
                  type={`${hide ? "text" : "password"}`}
                  placeholder="Password"
                  name="password"
                  autoComplete="on"
                  {...register("password", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                  })}
                />
                {hide ? (
                  <FontAwesomeIcon
                    onClick={() => setHide(!hide)}
                    className="absolute right-3 bottom-6 h-6 w-6 cursor-pointer"
                    icon={faEye}
                  />
                ) : (
                  <FontAwesomeIcon
                    onClick={() => setHide(!hide)}
                    className="absolute right-3 bottom-6 h-6 w-6 cursor-pointer"
                    icon={faEyeSlash}
                  />
                )}
              </div>
              {errors.password && (
                <p className="pt-4 text-md text-red-500">
                  {errors.password.type === "required" &&
                    "This field is required."}
                  {errors.password.type === "maxLength" &&
                    "password Max length is 25 char."}
                  {errors.password.type === "minLength" &&
                    "Password Min length is 6 char."}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.password && (
                  <p className="pt-4 text-md text-red-500">
                    {state.errors.password[0]}
                  </p>
                )
              }
            </div>

            {/** Confirm Password  */}

            <div className="w-1/2 md:w-full">
              <div className="relative flex flex-col items-start justify-center gap-2 ">
                <label htmlFor="password_confirmation">Confirm Password</label>
                <input
                  id="password_confirmation"
                  className="signin-inputs w-full"
                  type={`${hide ? "text" : "password"}`}
                  placeholder="Confirm Password"
                  name="password_confirmation"
                  autoComplete="on"
                  {...register("password_confirmation", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                    validate: (value) => value === getValues("password"),
                  })}
                />
                {hide ? (
                  <FontAwesomeIcon
                    onClick={() => setHide(!hide)}
                    className="absolute right-3 bottom-6 h-6 w-6 cursor-pointer"
                    icon={faEye}
                  />
                ) : (
                  <FontAwesomeIcon
                    onClick={() => setHide(!hide)}
                    className="absolute right-3 bottom-6 h-6 w-6 cursor-pointer"
                    icon={faEyeSlash}
                  />
                )}
              </div>
              {errors.password_confirmation && (
                <p className="pt-4 text-md text-red-500">
                  {errors.password_confirmation.type === "required" &&
                    "This field is required."}
                  {errors.password_confirmation.type === "maxLength" &&
                    "password_confirmation Max length is 25 char."}
                  {errors.password_confirmation.type === "minLength" &&
                    "Password Min length is 6 char."}
                  {errors.password_confirmation.type === "validate" &&
                    "Passwords don't match."}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.password_confirmation && (
                  <p className="pt-4 text-md text-red-500">
                    {state.errors.password_confirmation[0]}
                  </p>
                )
              }
            </div>
          </div>
        )}

        {/** student and parents mobile numbers fields */}
        <div className="flex w-full items-start justify-between gap-16 md:gap-6 md:flex-col ">
          {/** Student Mobile field */}

          <div className="w-1/2 md:w-full">
            <div className=" relative flex w-full flex-col items-start justify-center gap-2">
              <label htmlFor="mobile">Student Mobile</label>
              <div className="w-full">
                {/* <ReactFlagsSelect
                  selected={countrySelected}
                  onSelect={(code) => setCountrySelected(code)}
                  className="p-0"
                /> */}

                <PhoneInput
                  country={"253"}
                  value={countrySelected}
                  onChange={(phone) => setCountrySelected(phone)}
                  className="flex items-center signin-inputs w-full ml-0 !pl-2 gap-4 "
                  inputProps={{
                    name: "mobile",
                    value: mobile,
                    onChange: (e) => setMobile(e.target.value),
                    placeholder: "1234567891",
                    className: "ml-[50px] border-0 outline-0",
                    required: true,
                  }}
                />
                {/* <input
                    className=" outline-0"
                    type="number"
                    inputMode="numeric"
                    id="mobile"
                    placeholder="Student Mobile"
                    name="mobile"
                    min="1"
                    autoComplete="on"
                    {...register("mobile", {
                      required: true,
                      pattern: /^[\d]{11}/,
                      // maxLength: 11,
                      min: 1,
                    })}
                  /> */}
              </div>
            </div>
            {errors.mobile && (
              <p className="pt-4 text-md text-red-500">
                {errors.mobile.type === "required" && "This field is required."}
                {errors.mobile.type === "pattern" && " Invalid Mobile Number."}
                {errors.mobile.type === "maxLength" &&
                  " Invalid Mobile Number."}
                {errors.mobile.type === "min" && " Invalid Mobile Number."}
                {errors.mobile.type === "validate" &&
                  "Please, Don't Enter the Same Mobile Number."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.mobile && (
                <p className="pt-4 text-md text-red-500">
                  {state.errors.mobile[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.mobile && (
                  <p className="pt-4 text-md text-red-500">
                    {StudentEditErrors?.response?.data.errors.mobile[0]}
                  </p>
                )
            }
          </div>
          {/** Father's Mobile field */}

          <div className="w-1/2 md:w-full">
            <div className=" relative flex w-full flex-col items-start justify-center gap-2">
              <label htmlFor="parent_mobile">Parents Number</label>

              <PhoneInput
                country={"eg"}
                value={countryParentSelected}
                onChange={(phone) => setCountryParentSelected(phone)}
                className="flex items-center signin-inputs w-full ml-0 !pl-2 gap-4 "
                inputProps={{
                  name: "parent_mobile",
                  value: parentMobile,
                  onChange: (e) => setParentMobile(e.target.value),
                  placeholder: "1234567891",
                  className: "ml-[50px] border-0 outline-0",
                  required: true,
                }}
              />
            </div>
            {errors.parent_mobile && (
              <p className="pt-4 text-md text-red-500">
                {errors.parent_mobile.type === "required" &&
                  "This field is required."}
                {errors.parent_mobile.type === "pattern" &&
                  " Invalid Mobile Number."}
                {errors.parent_mobile.type === "maxLength" &&
                  " Invalid Mobile Number."}
                {errors.parent_mobile.type === "min" &&
                  " Invalid Mobile Number."}
                {errors.parent_mobile.type === "validate" &&
                  "Please, Don't Enter the Same Mobile Number."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.parent_mobile && (
                <p className="pt-4 text-md text-red-500">
                  {state.errors.parent_mobile[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.parent_mobile && (
                  <p className="pt-4 text-md text-red-500">
                    {StudentEditErrors?.response?.data.errors.parent_mobile[0]}
                  </p>
                )
            }
          </div>
        </div>

        {/** Stage  filed */}
        <div className="flex w-full items-end justify-between gap-16 md:flex-col md:items-start md:gap-6 ">
          {/* Gender  */}
          <div className=" flex w-full flex-col items-start justify-center gap-2 md:w-full">
            <label htmlFor="gender">Gender</label>
            <select
              name="gender"
              id="gender"
              {...register("gender", { required: true })}
            >
              <option value="0">Male</option>
              <option value="1">Famale</option>
            </select>
            {errors.gender && (
              <p className="pt-4 text-md text-red-500">
                {errors.gender.type === "required" && "This field is required."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.gender && (
                <p className="pt-4 text-md text-red-500">
                  {state.errors.gender[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.gender && (
                  <p className="pt-4 text-md text-red-500">
                    {StudentEditErrors?.response?.data.errors.gender[0]}
                  </p>
                )
            }
          </div>
          <div className="flex w-full flex-col items-start justify-center gap-2  md:w-full">
            <label htmlFor="grade">Grade</label>
            <select
              name="grade"
              id="grade"
              {...register("grade", { required: true })}
            >
              {/*       <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option> */}
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>

            {errors.class && (
              <p className="text-xs text-red-500 ">
                {errors.class.class === "required" && "This field is required."}
              </p>
            )}
            {
              //!-------server errors -----

              StudentEditErrors &&
                StudentEditErrors?.response?.data?.errors?.class && (
                  <p className="w-full text-end text-xs text-red-500  ">
                    {StudentEditErrors?.response?.data?.errors?.class[0]}
                  </p>
                )
            }
          </div>
        </div>

        <div className="flex w-full flex-col items-start justify-center gap-2 md:w-full">
          <label htmlFor="section">Courses</label>
          <select
            name="section"
            id="section"
            placeholder="Select Courses"
            {...register("section", { required: true })}
          >
            <option value="est">EST</option>
            <option value="act">ACT</option>
            <option value="dsat">DIGITAL SAT</option>
          </select>

          {errors.section && (
            <p className="text-xs text-red-500 ">
              {errors.section === "required" && "This field is required."}
            </p>
          )}
          {
            //!-------server errors -----

            StudentEditErrors &&
              StudentEditErrors?.response?.data?.errors?.section && (
                <p className="w-full text-end text-xs text-red-500  ">
                  {StudentEditErrors?.response?.data?.errors?.section[0]}
                </p>
              )
          }
        </div>
        <div className="flex flex-row w-full items-start justify-between gap-6 md:flex-col md:gap-6">
          <div className=" flex w-1/2 md:w-full flex-col items-start justify-center gap-2 ">
            <label
              className="text-start flex items-center gap-2"
              htmlFor="is_online"
            >
              Attendance
            </label>
            <select
              className=" signin-inputs signin-input"
              name="is_online"
              id="is_online"
              {...register("is_online", { required: true })}
            >
              <option value="1">Online</option>
              <option value="0">Center</option>
            </select>

            {errors.is_online && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {errors.is_online.type === "required" &&
                  "Please fill out this field"}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.is_online && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {state.errors.is_online[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.is_online && (
                  <p className="w-full text-start text-[14px] text-blue-900  ">
                    {StudentEditErrors?.response?.data.errors.is_online[0]}
                  </p>
                )
            }
          </div>

          {/** center or group */}
          {+watchPlace === 0 && (
            <div className=" flex w-1/2 md:w-full flex-col items-start justify-center gap-2 ">
              <label
                className="text-start flex items-center gap-2"
                htmlFor="group"
              >
                Group
              </label>
              <select
                className={` signin-inputs signin-input  w-full pl-4 ${
                  errors.group || state.errors.group ? "!border-[#f00]" : null
                } `}
                name="group"
                id="group"
                {...register("group", { required: true })}
              >
                <option value="" disabled selected>
                  Select Group
                </option>

                {watchPlace === "0" || watchPlace === 0 ? (
                  <>
                    {state.groups
                      /* .filter((group) => +group.stage === +watchGrade) */
                      .map((group) => (
                        <>
                          <option value={group.id}>{group.name}</option>
                        </>
                      ))}
                  </>
                ) : null}
              </select>

              {errors.group && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {errors.group.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.group && (
                  <p className="w-full text-start text-[14px] text-blue-900  ">
                    {state.errors.group[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.group && (
                    <p className="w-full text-start text-[14px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.group[0]}
                    </p>
                  )
              }
            </div>
          )}
        </div>
        {edit && (
          <div className="form_group-register flex gap-2 flex-wrap">
            <div className="flex w-full items-center justify-end gap-10 md:justify-center">
              <label className="visibility-switch order-2 text-start">
                <input
                  className=""
                  id="active"
                  name="active"
                  {...register("is_advanced", {
                    required: false,
                  })}
                  type="checkbox"
                />
                <span className="visibility-slider">
                  <svg
                    className="slider-icon"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path fill="none" d="m4 16.5 8 8 16-16"></path>
                  </svg>
                </span>
              </label>
              <div>
                <label
                  className="w-full cursor-pointer truncate order-1 text-start"
                  htmlFor="active"
                >
                  Advanced
                </label>
              </div>
            </div>

            <div className="flex w-full items-center justify-end gap-10 md:justify-center">
              <label className="visibility-switch order-2 text-start">
                <input
                  className=""
                  id="active"
                  name="active"
                  {...register("is_beginner", {
                    required: false,
                  })}
                  type="checkbox"
                />
                <span className="visibility-slider">
                  <svg
                    className="slider-icon"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path fill="none" d="m4 16.5 8 8 16-16"></path>
                  </svg>
                </span>
              </label>
              <div>
                <label
                  className="w-full cursor-pointer truncate order-1 text-start"
                  htmlFor="active"
                >
                  Beginner
                </label>
              </div>
            </div>

            {errors.sectionType && (
              <p className="text-xs text-red-500 ">
                {errors.sectionType === "required" && "This field is required."}
              </p>
            )}
            {
              //!-------server errors -----

              StudentEditErrors &&
                StudentEditErrors?.response?.data?.errors?.sectionType && (
                  <p className="w-full text-end text-xs text-red-500  ">
                    {StudentEditErrors?.response?.data?.errors?.sectionType[0]}
                  </p>
                )
            }
          </div>
        )}

        {/** active and magnetic_id fields */}
        {edit && (
          <div className="flex flex-row-reverse w-full items-center justify-between gap-16 md:flex-col md:gap-6">
            {/** active filed */}
            <div className=" active flex w-1/2 flex-col items-end justify-center gap-2 md:w-full ">
              <div className="flex w-full items-center justify-end gap-10 md:justify-center">
                <label className="visibility-switch order-2 text-start">
                  <input
                    className=""
                    id="active"
                    name="active"
                    {...register("active", {
                      required: false,
                    })}
                    type="checkbox"
                  />
                  <span className="visibility-slider">
                    <svg
                      className="slider-icon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  </span>
                </label>
                <div>
                  <label
                    className="w-full cursor-pointer truncate order-1 text-start"
                    htmlFor="active"
                  >
                    Activation
                  </label>
                </div>
              </div>

              {errors.active && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.active.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.active && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.active[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.active && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.active[0]}
                    </p>
                  )
              }
            </div>
            {/**magnetic_id */}
            <div className=" flex w-1/2 flex-col items-start justify-center  gap-2    md:w-full">
              <label className="text-start" htmlFor="magnetic_id">
                Activation card
              </label>
              <input
                className="signin-inputs pl-4    w-full"
                type="text"
                id="magnetic_id"
                placeholder="Activation card"
                name="magnetic_id"
                autoComplete="on"
                {...register("magnetic_id", {
                  pattern: /^[A-Za-z0-9-_]*$/,
                  minLength: 3,
                })}
              />
              {errors.magnetic_id && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {errors.magnetic_id.type === "required" &&
                    "Please fill out this field"}
                  {errors.magnetic_id.type === "pattern" &&
                    "Only English letters and numbers are allowed"}
                  {errors.magnetic_id.type === "maxLength" &&
                    "The maximum number of characters is 20 characters"}
                  {errors.magnetic_id.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.magnetic_id && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.magnetic_id[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.magnetic_id && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.magnetic_id[0]}
                    </p>
                  )
              }
            </div>
          </div>
        )}

        {/* Countries */}
        <div className=" flex w-full flex-col items-start justify-center gap-2 md:w-full ">
          <label
            className="text-start flex items-center gap-2"
            htmlFor="countries"
          >
            Countries
          </label>
          <select
            className="state-selector"
            name="countries"
            id="countries"
            defaultValue={"63"}
            {...register("countries", { required: true })}
          >
            {state?.states?.map((state) => (
              <option
                key={state.id}
                value={state.id}
                selected={
                  studentInfo?.data[0]?.country
                    ? studentInfo?.data[0]?.country === state.id
                    : state.id === 63
                }
              >
                {state.Country}
              </option>
            ))}
          </select>
          {errors.countries && (
            <p className="w-full text-start text-[12px] text-blue-900  ">
              {errors.countries.type === "required" &&
                "Please fill out this field"}
            </p>
          )}
        </div>

        {/** Governorate and City fields */}
        <div className="flex flex-row-reverse w-full items-start justify-between gap-6 md:flex-col">
          {/** Governorate filed */}
          <div className=" flex w-full md:w-full flex-col items-start justify-center gap-2 md:w-full ">
            {String(watchCountries) === "63" ? (
              <>
                <label
                  className="text-start flex items-center gap-2"
                  htmlFor="governorate"
                >
                  Governorate
                </label>
                <select
                  name="governorate"
                  id="governorate"
                  defaultValue={"1"}
                  {...register("governorate", { required: false })}
                >
                  {state?.governorate?.map((city) => (
                    <option
                      key={city.id}
                      value={city.id}
                      selected={
                        studentInfo?.data[0]?.governorate
                          ? studentInfo?.data[0]?.governorate === city.id
                          : city.id === 1
                      }
                    >
                      {city.state}
                    </option>
                  ))}
                </select>
                {errors.governorate && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.governorate.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.governorate && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.governorate[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.governorate && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {
                          StudentEditErrors?.response?.data.errors
                            .governorate[0]
                        }
                      </p>
                    )
                }
              </>
            ) : null}
          </div>
        </div>

        {/** School and Activation Code fields */}
        <div className="flex w-full items-start justify-between gap-12 md:flex-col  md:gap-6">
          {/** School  */}
          <div
            className={` flex w-full flex-col items-start justify-center gap-2 md:w-full`}
          >
            <label htmlFor="school">School Name</label>
            <input
              className="signin-inputs  w-full"
              type="text"
              id="school"
              placeholder="School"
              name="school"
              autoComplete="on"
              {...register("school", {
                required: true,
                //pattern: /^[A-Za-z]+$/,
                maxLength: 80,
                minLength: 4,
              })}
            />
            {errors.school && (
              <p className="pt-4 text-md text-red-500">
                {errors.school.type === "required" && "This field is required."}
                {errors.school.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
                {errors.school.type === "maxLength" &&
                  "Available name max length is 80."}
                {errors.school.type === "minLength" &&
                  " Please enter at least 4 letters."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.school && (
                <p className="pt-4 text-md text-red-500">
                  {state.errors.school[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.school && (
                  <p className="pt-4 text-md text-red-500">
                    {StudentEditErrors?.response?.data.errors.school[0]}
                  </p>
                )
            }
          </div>
        </div>

        {/** User Image  */}
        {!edit && (
          <div className="flex w-full flex-col items-end gap-2">
            <label
              className="text-start flex items-center gap-2"
              htmlFor="profile_pic"
            >
              Upload a personal photo (Max Size 5 MB only Jpg/Jpeg/Png/Webp )
            </label>

            <input
              id="profile_pic"
              className="signin-inputs pl-4    w-full"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/webp"
              name="profile_pic"
              {...register("profile_pic", {
                required: edit ? false : true,
                validate: (value) => !(value[0]?.size > 5000000),
              })}
            />

            {errors.profile_pic && (
              <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                {errors.profile_pic.type === "required" &&
                  "Please add a personal photo"}
                {errors.profile_pic.type === "validate" &&
                  "Maximum image size is 5MB"}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.profile_pic && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {state.errors.profile_pic[0]}
                </p>
              )
            }
          </div>
        )}

        {/** Submit Button */}
        <button
          disabled={!isValid || state.submitLoading || StudentEditLoading}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {state.submitLoading || StudentEditLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : edit ? (
            "Edit Student"
          ) : (
            "Register"
          )}
        </button>
      </form>
    </section>
  );
}

export default Register;
