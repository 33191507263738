import HelmetTags from "../../../MainComponents/HelmetTags.jsx";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { useAuthDispatch } from "../../../MainComponents/GlobalContext.jsx";
import axios from "axios";
import { toast } from "react-hot-toast";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import logoLight from "../../../assets/logo-light.png";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "../../../SASS/Register.scss";

function SignIn({ admin }) {
  const AuthDispatch = useAuthDispatch();
  const navigate = useNavigate();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [hide, setHide] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [forgetPassowrdLoading, setForgetPassowrdLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const handleSignIn = (data) => {
    setSubmitLoading(true);
    async function postData() {
      try {
        let res = await axios.post(
          admin
            ? process.env.REACT_APP_ADMIN_LOGIN_API
            : process.env.REACT_APP_LOGIN_API,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        let userData = await res.data;
        setSubmitLoading(false);
        localStorage.setItem("userData", JSON.stringify(userData));
        AuthDispatch({ type: "setUserData", payload: userData });

        // set axios
        axios.defaults.headers.common["Authorization"] = JSON.parse(
          localStorage.userData
        ).student_token;

        toast.success(userData?.message);
        reset();
        navigate(`${admin ? "/admin/online-students" : "/homework"}`, {
          replace: true,
        });
      } catch (error) {
        setSubmitLoading(false);
        setServerErrors(error?.response?.data?.error);
        toast.error(
          error?.response?.data?.error || "An error occurred, please try again."
        );
      }
    }
    postData();
  };

  const handleForgotPassword = (data) => {
    setForgetPassowrdLoading(true);
    //!  if success
    setForgotPassword(false);
    setForgetPassowrdLoading(false);
    //!  if error
    setForgetPassowrdLoading(false);
    setServerErrors();

    reset();
  };

  return (
    <section className="flex h-fit min-h-screen flex-col items-center justify-center gap-5 py-8 dark:bg-dark sm:py-14">
      <HelmetTags
        title="Sign in | Ms Amira Shaltout"
        description="Sign in | Ms Amira Shaltout"
        index
      >
        <link rel="canonical" href="https://amirashaltout.com/signin" />
      </HelmetTags>

      <div
        //!welcome-message
        className="welcome-message  flex flex-col items-center  justify-center"
      >
        <Link className="h-60 w-auto text-4xl " to="/">
          <img
            className="h-full"
            src={
              "https://api.amirashaltout.com/uploads/assets/logo-dash-light.png"
            }
            alt="logo"
          />
        </Link>
        <h1 className="mb-3 mt-10 font-bold  ">Log in | Ms Amira Shaltout</h1>
        <h2 className="opacity-80">
          Welcome back! Please enter your information
        </h2>
      </div>

      <div className="container-register -mt-7">
        <form
          //!form
          onSubmit={handleSubmit(handleSignIn)}
          method="post"
          className="form_area-register"
        >
          <h1 className={`title-register ${admin ? "text-3xl" : ""}`}>
            Sign In
          </h1>
          <>
            {/**
             * //!Username filed
             *
             */}
            {admin ? (
              <div className=" form_group-register">
                <label htmlFor="username" className="sub_title-register">
                  User name
                </label>
                <input
                  className="form_style-register "
                  type="text"
                  id="username"
                  placeholder="User name"
                  name="username"
                  autoComplete="on"
                  {...register("username", {
                    required: true,
                    pattern: /^[A-Za-z0-9-_]*$/,
                    maxLength: 20,
                    minLength: 3,
                  })}
                />
                {errors.username && (
                  <p className="text-[12px] text-blue-900 mt-5 ">
                    {errors.username.type === "required" &&
                      "Please enter your username or email"}
                    {errors.username.type === "pattern" &&
                      "Only letters and numbers are allowed"}
                    {errors.username.type === "maxLength" &&
                      "The maximum number of characters is 20 characters"}
                    {errors.username.type === "minLength" &&
                      "The minimum number of letters is 3 letters"}
                  </p>
                )}
              </div>
            ) : (
              <div className="form_group-register">
                <label htmlFor="mobile" className="sub_title-register">
                  Mobile
                </label>
                <input
                  className="form_style-register"
                  type="text"
                  id="mobile"
                  placeholder="mobile"
                  name="mobile"
                  autoComplete="on"
                  {...register("mobile", {
                    required: true,
                    pattern: /^[A-Za-z0-9-_]*$/,
                    maxLength: 20,
                    minLength: 3,
                  })}
                />
                {errors.mobile && (
                  <p className="text-[12px] text-blue-900 py-2 ">
                    {errors.mobile.type === "required" &&
                      "Please enter your mobile or email"}
                    {errors.mobile.type === "pattern" &&
                      "Only letters and numbers are allowed"}
                    {errors.mobile.type === "maxLength" &&
                      "The maximum number of characters is 20 characters"}
                    {errors.mobile.type === "minLength" &&
                      "The minimum number of letters is 3 letters"}
                  </p>
                )}
              </div>
            )}
          </>

          {/* {!admin && (
            <div className={`w-full md:w-full`}>
              <div className="  flex w-full flex-col items-start justify-center gap-2">
                <label className="text-start" htmlFor="mobile">
                  Mobile
                </label>
                <div className="relative w-full">
                  <input
                    className="signin-inputs pl-4   w-full "
                    type="text"
                    id="mobile"
                    placeholder="Mobile"
                    name="mobile"
                    autoComplete="on"
                    {...register("mobile", {
                      required: false,
                      pattern: /^[\d]{11}/,
                      maxLength: 11,
                    })}
                  />
                  <FontAwesomeIcon
                    className="absolute bottom-1/2 right-4 h-6  w-7 translate-y-1/2"
                    icon={faWhatsapp}
                  />
                </div>
              </div>
              {errors.mobile && (
                <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                  {errors.mobile.type === "required" &&
                    "Please fill out this field"}
                  {errors.mobile.type === "pattern" &&
                    "Please enter an 11-digit phone number"}
                  {errors.mobile.type === "maxLength" &&
                    "Please enter an 11-digit phone number"}
                  {errors.mobile.type === "validate" &&
                    "Please enter a different phone number"}
                </p>
              )}

              {
                //!-------server errors -----

                errors.errors && errors.errors.mobile && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.errors.mobile[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                errors?.response?.data?.errors &&
                  errors?.response?.data?.errors?.mobile && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {errors?.response?.data.errors.mobile[0]}
                    </p>
                  )
              }
            </div>
          )} */}

          {/**
           * //!Password filed
           *  */}

          <div className=" form_group-register ">
            <label htmlFor="password" className="sub_title-register">
              Password
            </label>
            <div
              //!input password relative
              className="relative-hide relative h-auto w-full"
            >
              <input
                id="password"
                className="form_style-register"
                type={`${hide ? "text" : "password"}`}
                placeholder="Password"
                autoComplete="on"
                name="password"
                {...register("password", {
                  required: true,
                  maxLength: 25,
                  minLength: 6,
                })}
              />
               <div
                //!eye icons
                onClick={() => setHide(!hide)}
                className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
              >
                {hide ? (
                  <FontAwesomeIcon className="h-full w-full" icon={faEye} />
                ) : (
                  <FontAwesomeIcon
                    className="h-full w-full"
                    icon={faEyeSlash}
                  />
                )}
              </div> 
            </div>
            {errors.password && (
              <p className="text-[12px] text-blue-900 mt-2 ">
                {errors.password.type === "required" &&
                  "Please enter your password"}
                {errors.password.type === "maxLength" &&
                  "The maximum number of characters is 25 characters"}
                {errors.password.type === "minLength" &&
                  "The minimum number of letters is 6 letters"}
              </p>
            )}

            {
              //!--- server errors --------
              serverErrors && (
                <p className="text-[12px] text-blue-900 pt-2">{serverErrors}</p>
              )
            }
          </div>
          <div className="link-register  ">
            <Link to="/forget-password">
              <span className="hover:underline">Forgot your password?</span>
            </Link>
          </div>

          <button
            disabled={!isValid || submitLoading}
            className="btn-register "
            type="submit"
          >
            {submitLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Sign in"
            )}
          </button>
          {!admin && (
            <div className="link-register text-wrap max-w-[250px]">
              Don't have an account?
              <Link
                className="font-bold text-[#9B6143] hover:underline "
                to="/register"
              >
                Create account now
              </Link>
            </div>
          )}
        </form>
      </div>
    </section>
  );
}

export default SignIn;
