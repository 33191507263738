import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const QuizTimer = ({ state, duration, onFinishQuiz }) => {
  const { ID } = useParams();
  const initialDuration = duration * 60;
  const [timerDuration, setTimerDuration] = useState(initialDuration);

  const [formattedTime, setFormattedTime] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setTimerDuration((prevDuration) => prevDuration - 1);
    }, 1000);

    if (timerDuration <= 0) {
      onFinishQuiz();
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [timerDuration]);



  useEffect(() => {
    if (timerDuration === 0) {
      // console.log("Timer ended!"); // Replace this with your desired code or action
    } else {
      const minutes = Math.floor(timerDuration / 60);
      const seconds = timerDuration % 60;
      const formatted = `${minutes.toString().padStart(2, "0")} min : ${seconds
        .toString()
        .padStart(2, "0")} sec`;
      setFormattedTime(formatted);
    }
  }, [timerDuration]);


  return (
    <div className="flex w-full flex-col items-center justify-center md:w-fit">
      <div className="text-white font-bold">{formattedTime}</div>

    </div>
  );
};

export default QuizTimer;