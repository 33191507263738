import { motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  faAnglesLeft,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "./GlobalContext.jsx";
import { Popconfirm } from "antd";
import { useEffect, useState } from "react";
import useAxios from "./Hooks/useAxios.jsx";

function SingleChapter({
  chapter,
  admin,
  reFetch,
  setReFetch,
  notSigned,
  type,
}) {
  const AuthState = useAuthState();
  const navigate = useNavigate();
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!--------- delete Chapter -------
  let api =
    type === "homework"
      ? process.env.REACT_APP_DELETE_HOMEWORK_API
      : process.env.REACT_APP_ADMIN_DELETE_COURSE_API;
  const [deleteChapterSuccess, deleteChapterErrors, deleteloading] = useAxios(
    api,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  function handleDeleteChapter(id) {
    setSubmitDelete({
      flag: "deleteChapter",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  useEffect(() => {
    //! refetch the data on success delete or accept request

    if (deleteChapterSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteChapterSuccess]);

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="course group relative h-[390px]  w-[350px] overflow-hidden rounded-[25px] border-2   bg-white shadow-3xl sm:w-[300px]"
    >
      <div className="img relative z-[0] h-full w-full  overflow-hidden rounded-[20px]">
        <img
          onClick={() =>
            navigate(
              `${
                notSigned
                  ? "/signin"
                  : admin
                    ? type === "homework" &&
                      `/admin/homework/add-question/${chapter.key}`
                    : admin
                      ? type === "exam" &&
                        `/admin/exam/${chapter.key}/add-placement-test`
                      : admin
                        ? `/admin/lectures/chapters/${chapter.key}`
                        : type === "homeworkstudent"
                          ? `/homework/solve-placement-test/${chapter.key}`
                          : `/lectures/chapters/${chapter.key}`
              }`
            )
          }
          className={`absolute h-full w-full rounded-[20px] object-cover  opacity-80 duration-300 hover:scale-125`}
          src={chapter.img}
          alt={chapter.name}
        />
        <div className=" absolute top-5 right-5 z-50 pointer-events-auto  cursor-pointer rounded-2xl border-2 border-blue-600 bg-blue-600 px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-600 active:scale-90">
          {chapter.section === null ? "VTA" : chapter.section}
        </div>
        {type === "homework" && (
          <>
            <div className=" absolute top-5 left-5 z-50 pointer-events-auto cursor-pointer rounded-2xl border-2 border-black bg-yellow   px-3 py-1 text-center font-semibold text-black duration-300 hover:bg-light hover:text-black active:scale-90">
              {chapter.is_advanced === 1 ? "Advanced" : "Beginner"}
            </div>
            <div className=" absolute top-5 right-[35%] z-50 pointer-events-auto  cursor-pointer rounded-2xl  border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
              {chapter.is_reading === 1 ? "Reading" : "Writing"}
            </div>
          </>
        )}

        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_show_creator")
          )) &&
          admin &&
          chapter.created_by && (
            <div className="creator pointer-events-none absolute right-12 top-12 flex h-10 w-fit items-center justify-center gap-3 rounded-xl bg-secondary px-3 text-xl font-semibold text-light">
              By {chapter.created_by}
            </div>
          )}
      </div>
      <div className="chapter-details pointer-events-none absolute bottom-0 right-0 z-20 mt-7 flex h-fit w-full translate-y-14 flex-col overflow-hidden bg-red-100/80 p-8 pb-0  transition-all duration-300 ease-in-out group-hover:translate-y-0 md:translate-y-0  ">
        <h3
          onClick={() =>
            navigate(
              `${
                notSigned
                  ? "/signin"
                  : admin
                    ? type === "homework" &&
                      `/admin/homework/add-question/${chapter.key}`
                    : admin
                      ? `/admin/lectures/chapters/${chapter.key}`
                      : type === "homeworkstudent"
                        ? `/homework/solve-placement-test/${chapter.key}`
                        : `/lectures/chapters/${chapter.key}`
              }`
            )
          }
          className={`title pointer-events-auto mb-7 w-fit max-w-full cursor-pointer  text-2xl font-bold text-secondary `}
        >
          {type === "homeworkstudent" || type === "homework"
            ? chapter?.name?.substring(0, 33)
            : chapter?.title?.substring(0, 33)}
        </h3>
        <p className="description text-start text-2xl font-semibold leading-relaxed text-secondary/70">
          {`${
            chapter?.description === null
              ? ""
              : `${chapter?.description?.substring(0, 70)}...`
          }`}
        </p>

        <div
          className={`visibility-cta  mt-5 flex flex-wrap gap-5 w-full border-t-2 border-secondary  ${
            admin ? "justify-between" : "justify-center"
          } translate-y-full items-center  py-9 opacity-0  transition-all duration-300 ease-in-out group-hover:translate-y-0 group-hover:opacity-100 md:translate-y-0 md:opacity-100 `}
        >
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_visibility")
            )) &&
            admin && (
              <div className="visibility pointer-events-none  flex h-10 w-fit  items-center justify-center rounded-xl bg-secondary px-3 text-xl font-semibold text-light">
                {chapter.visibility === 0 ? "hidden" : "visible"}
              </div>
            )}
          {admin ? (
            <div className="admin-options flex flex-wrap w-full sm:justify-start sm:gap-5 justify-end gap-14">
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("homework_student")
                )) &&
                type === "homework" && (
                  <Link
                    to={`${`/admin/homework/All-students/${chapter.key}`}`}
                    className="pointer-events-auto  cursor-pointer rounded-2xl border-2 border-black bg-yellow px-3 py-1 text-center font-semibold text-black duration-300 hover:bg-light hover:text-black active:scale-90"
                  >
                    Students
                  </Link>
                )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_delete")
                )) && (
                <Popconfirm
                  okText="Confirm"
                  okType="red"
                  cancelText="Cancel"
                  color="#b1e8f4"
                  //okButtonProps={{ loading: loading }}
                  onConfirm={() => handleDeleteChapter(chapter.key)}
                  title="Do you really want to delete the chapter?"
                >
                  <button className="pointer-events-auto cursor-pointer rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                    Delete
                  </button>
                </Popconfirm>
              )}

              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_edit")
                )) && (
                <Link
                  to={`${
                    type === "homework"
                      ? `/admin/homework/edit-homework/${chapter.key}`
                      : ` /admin/lectures/edit-chapter/${chapter.key} `
                  }`}
                  className="pointer-events-auto w-24 cursor-pointer rounded-2xl border-2 border-blue-600 bg-blue-600 px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-600 active:scale-90"
                >
                  Edit
                </Link>
              )}
            </div>
          ) : (
            <Link
              to={notSigned ? "/signin" : `/lectures/chapters/${chapter.key}`}
              className="cta pointer-events-auto flex items-center justify-center gap-2 rounded-2xl border-2 border-secondary  bg-accent   p-3  text-light transition-all duration-300 ease-in-out hover:gap-4 active:scale-90 "
            >
              View lectures
            </Link>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default SingleChapter;
